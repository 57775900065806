@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Spartan:wght@400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
:root {
--color-primary: #40904d;
--color-secondary: #ee4a62;
--color-textSecondary: #ee4a62;
--color-tertiary: #f8b81f;
--color-dark: #111212;
--color-heading: #181818;
--dark-color-heading: #ffffff;
--color-border: #e5e5e5;
--dark-color-border: #282f3a;
--color-body: #808080;
--gradient-primary: linear-gradient(-90deg, #31b978 0%, #1ab69d 100%);
--bg-color: #EAF0F2;
--dark-bg-color: #020b17;
--cta-color-bg: #F0F4F5;
--color-bg-body: #ffffff;
--dark-color-bg-body: #111822;
--bg-color-2: #1c242f;
--color-white: #ffffff;
--color-black: #000000;
--footer-text: #181818;
--dark-footer-text: #808080;
--edu-btn-color: #ffffff;
--color-title: #bababa;
--color-gray-100: #f4f5f8;
--color-gray-200: #e3e7ed;
--color-gray-300: #cdd4e0;
--color-gray-400: #b4bdce;
--color-gray-500: #97a3b9;
--color-gray-600: #677793;
--color-gray-700: #596882;
--color-gray-800: #3b4863;
--color-gray-900: #1c273c;
--color-lighten01: #f0f4f5;
--color-lighten02: #edf5f8;
--color-lighten03: #f5f1eb;
--color-lighten04: #f7f5f2;
--color-extra01: #0ecd73;
--color-extra02: #8e56ff;
--color-extra03: #f92596;
--color-extra04: #5866eb;
--color-extra05: #f8941f;
--color-extra06: #39c0fa;
--color-extra07: #da04f8;
--color-extra08: #4664e4;
--color-extra09: #525151;
--color-extra10: #404040;
--color-extra11: #22272e;
--color-success: #3EB75E;
--color-danger: #FF0003;
--color-warning: #FF8F3C;
--color-info: #1BA2DB;
--color-placeholder: #808080;
--color-facebook: #1877f2;
--color-twitter: #1da1f2;
--color-youtube: #cd201f;
--color-linkedin: #0a66c2;
--color-pinterest: #E60022;
--color-instagram: #C231A1;
--color-vimeo: #00ADEF;
--color-twitch: #6441A3;
--color-discord: #7289da;
--border-width: 2px;
--radius-small: 4px;
--radius: 10px;
--radius-big: 16px;
--p-light: 300;
--p-regular: 400;
--p-medium: 500;
--p-semi-bold: 600;
--p-bold: 700;
--p-extra-bold: 800;
--shadow-darker: 0px 10px 50px 0px rgba(26,46,85,0.1);
--shadow-darker2: 0px 20px 50px 0px rgba(26,46,85,0.1);
--shadow-dark: 0px 10px 30px 0px rgba(20,36,66,0.15);
--shadow-darkest: 0px 10px 30px 0px rgba(0,0,0,0.05);
--shadow-darker3: 0px 4px 50px 0px rgba(0, 0, 0, 0.1);
--shadow-darker4: 0px 20px 70px 0px rgba(15, 107, 92, 0.2);
--transition: 0.3s;
--transition-2: 0.5s;
--transition-transform: transform .65s cubic-bezier(.23,1,.32,1);
--font-primary: 'Poppins', sans-serif;
--font-secondary: 'Spartan', sans-serif;
--font-third: 'Dancing Script', cursive;
--font-icomoon: 'icomoon';
--font-size-b1: 15px;
--font-size-b2: 13px;
--line-height-b1: 1.73;
--line-height-b2: 1.85;
--h1: 50px;
--h2: 36px;
--h3: 28px;
--h4: 20px;
--h5: 18px;
--h6: 16px;
--h1-lineHeight: 1.2;
--h2-lineHeight: 1.39;
--h3-lineHeight: 1.43;
--h4-lineHeight: 1.4;
--h5-lineHeight: 1.45;
--h6-lineHeight: 1.62;
}
/*===================================================================*/
body{
font-size: 13px !important;
font-family: var(--font-primary) !important;
line-height: 24px !important;
}
a{
text-decoration: none !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
font-family: var(--font-secondary) !important;
font-weight: 700 !important;
}
.container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
padding-left: 45px  !important;
padding-right: 45px !important;
}
@media only screen and (max-width: 960px) {
.container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
padding-left: 15px  !important;
padding-right: 15px !important;
}
}
@media (min-width: 1200px) {
.container, .container-lg, .container-md, .container-sm, .container-xl {
max-width: 1200px !important;
}
}
/*===================================================================*/
.bg-lighten03 {
background-color: var(--color-lighten03);
}
.tx-12{
font-size: 12px;
}
.tx-white{
color:var(--color-white) !important;
}
.tx-theme{
color:var(--color-primary) !important;
}
.border-right{
border-right: 1px solid var(--color-gray-900);
}
.wd-24{
width: 24px;
}
.w-xs {
min-width: 80px;
}
.w-sm {
min-width: 95px;
}
.w-md {
min-width: 110px;
}
.w-lg {
min-width: 160px;
}
.img-fluid{
    width: 100%;
}
.btn-lg{
padding: 12px !important;
font-size: 16px !important;
}
.btn{
border-radius: 5px !important;
}
.btn-icon-round {
text-align: center;
height: 60px;
width: 60px;
line-height: 60px;
background-color: var(--color-primary);
border-radius: 50%;
color: var(--color-white);
font-size: 12px;
display: flex;
align-items: center;
justify-content: center;
}
.btn-icon-round:hover {
color: var(--color-white);
background-color: var(--color-secondary);
}
a.edu-btn, button.edu-btn {
text-align: center;
border-radius: 5px;
display: inline-block;
height: 60px;
line-height: 62px;
color: var(--edu-btn-color);
background: var(--color-primary);
padding: 0 30px;
font-size: 15px;
font-weight: 500;
-webkit-transition: 0.4s;
transition: 0.4s;
font-family: var(--font-secondary);
border: 0 none;
overflow: hidden;
position: relative;
z-index: 1;
}
a.edu-btn:after,
button.edu-btn:after {
content: "";
height: 100%;
width: 0;
background: -webkit-linear-gradient(right, #31b978 0%, #1ab69d 100%);
background: linear-gradient(-90deg, #31b978 0%, #1ab69d 100%);
border-radius: 5px;
position: absolute;
top: 0;
right: 0;
bottom: 0;
z-index: -1;
-webkit-transition: 0.4s;
transition: 0.4s;
}
a.edu-btn:hover:after,
button.edu-btn:hover:after {
left: 0;
width: 100%;
}
a.edu-btn.btn-large,
button.edu-btn.btn-large {
padding: 0 40px;
}
a.edu-btn.btn-large i,
button.edu-btn.btn-large i {
top: 0;
padding-left: 10px;
}
a.edu-btn.btn-medium,
button.edu-btn.btn-medium {
height: 50px;
line-height: 51px;
padding: 0 25px;
}
a.edu-btn.btn-small,
button.edu-btn.btn-small {
height: 40px;
line-height: 44px;
padding: 0 15px;
font-size: 12px;
}
a.edu-btn.btn-border, button.edu-btn.btn-border {
background-color: transparent;
border: 1px solid var(--color-border);
color: var(--color-white);
padding: 0 25px;
}
a.edu-btn i, button.edu-btn i {
padding-left: 6px;
position: relative;
font-size: 11px;
}
.section-gap-sm{
    padding: 60px 0px;
    }
.section-gap-md{
padding: 80px 0px;
}
@media only screen and (max-width: 960px) {
.section-gap-md{
padding: 30px 0px;
} 
.section-gap-sm{
    padding: 20px 0px;
    }
}
.section-title {
margin-bottom: 44px;
}
.section-title.section-center {
text-align: center;
}
.section-title .pre-title {
font-weight: 600;
display: inline-block;
margin-bottom: 14px;
font-family: var(--font-third);
font-size: 24px;
color: var(--color-primary);
}
.section-title .shape-line {
display: block;
color: var(--color-primary);
}
.section-title h2{
font-size: 36px;
line-height: 52px;
}
.section-title h3{
    font-size: 26px;
    line-height: 42px;
    }
.section-title p {
margin-bottom: 24px;
margin-top: 15px;
}
@media only screen and (max-width: 960px) {
.section-title {
margin-bottom: 24px;
}
.section-title h2{
font-size: 20px;
line-height: 32px;
}
.section-title p {
margin-bottom: 24px;
margin-top: 10px;
font-size: 12px;
}
}
.bgImagePosition, .bg-image {
background-repeat: no-repeat;
background-size: cover;
background-position: center center;
}
.justify-content-between{
justify-content:space-between;
}
.borderradius05{
    border-radius: 5px;
}
/*===================================================================*/
.header {
box-shadow: 0 6px 15px 0 rgba(0,0,0,.05);
position: relative;
width: 100%;
}
.header .container, .header .container-fluid{
display: flex;
align-items: center;
}
.header-center, .header-left, .header-right {
display: flex;
align-items: center;
}
.header-left {
flex: 1;
}
.header-right {
flex: 1;
}
.header-left .logo{
margin-right: 75px;
max-width: 300px;
display: block;
position: absolute;
top: -25px;
left: 25px;
}
.header-left .logo img{
width: 100%;
height: auto;
}
.navbar{
position:static !important;
padding:0px !important;
}
.nav-link{
padding: 2rem .7rem !important;
font-size: 14px !important;
font-weight: 600 !important;
position:relative;
font-family: var(--font-secondary) !important;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
}
.dropdown-hover:hover>.dropdown-menu {
display: inline-block;
}
.dropdown-hover>.dropdown-toggle:active {
/*Without this, clicking will make it sticky*/
pointer-events: none;
}
.submenu{
border: 0px !important;
background:var(--color-white) !important;
margin:0 !important;
min-width: 15rem !important;
padding:0 !important;
border-radius:0px !important;
}
.submenu .dropdown-item {
display: block;
width: 100%;
padding: 9px 15px;
clear: both;
font-weight: 400;
text-align: inherit;
text-decoration: none;
white-space: nowrap;
background-color: transparent;
border: 0;
border-bottom-width: 0px;
border-bottom-style: none;
border-bottom-color: currentcolor;
font-size: 13px;
border-bottom: 1px dotted #ddd;
}
.submenu .dropdown-item:hover{
background:var(--color-gray) !important;
color:var(--color-primary) !important;
}
.mheader {
display: flex;
align-items: center;
background: #fff;
background: var(--color-white);
border-bottom: 1px solid #eee;
box-shadow: 0 10px 50px 0 rgba(26,46,85,.1);
box-shadow: var(--shadow-darker);
height: 60px;
justify-content: space-between;
padding: 0 15px;
position: relative;
width: 100%;
}
.mheader-left {
display: flex;
align-items: center;
}
.sidenav-trigger {
font-size: 18px;
margin-right: 10px;
}
.upperheader{
align-items: center;
background: #f5f5f5;
display: flex;
height: 42px;
position: relative;
}
.topheader{
align-items: center;
background: var(--color-primary);
display: flex;
height: 42px;
position: relative;
}
.topheader::before {
background: #fff;
content: "";
height: 30px;
left: calc(27% - 15px);
position: absolute;
top: 50%;
transform: translateY(-50%) rotate(45deg);
width: 30px;
z-index: 1;
}
.topheader::after {
background: #fff;
content: "";
height: 100%;
left: 0;
position: absolute;
width: 27%;
}
.header-social{
display: flex;
align-items: center;
justify-content: flex-end;
}
.header-social ul{
margin: 0;
padding: 0;
list-style: none;
}
.header-social ul li{
display: inline-block;
margin-left: 20px;
}
.header-social ul li a{
display: inline-block;
font-size: 16px;
color: var(--color-white);
margin-top: 5px;
}
.header-info{
display: flex;
align-items: center;
padding-left: 25px;
}
.header-info ul{
margin: 0;
padding: 0;
list-style: none;
}
.header-info li {
display: inline-block;
margin: 0;
font-family: var(--font-secondary);
font-size: 13px;
color: var(--color-white);
padding: 14px 10px 12px;
position: relative;
}
.header-info li i {
font-size: 15px;
color: var(--color-white);
padding-right: 10px;
position: relative;
top: 1px;
}
.header-info li a{
color: var(--color-white);
font-weight: 600;
}
.header-info li::after {
content: "";
height: 100%;
width: 1px;
background-color: rgba(255, 255, 255, 0.1);
position: absolute;
top: 50%;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%);
right: 0;
}
@media only screen and (max-width: 960px) {
.topheader{
height: auto;
}
.topheader::before{
display: none;
}
.topheader::after{
display: none;
}
.header-info{
display: flex;
align-items: center;
padding-left: 0px;
}
.header-info li{
padding: 14px 0px 12px;
}
.header-info li::after{
display: none;
}
.header-info li i{
padding-right: 5px;
}
.bookbutton{
font-size: 11px !important;
padding: 9px 8px 5px !important;
height: auto !important;
line-height: 14px !important;
border-radius: 3px !important;
width: 100px;
}
}
.subheader{
width: 100%;
position: relative;
z-index: 1;
padding: 120px 0 110px;
overflow: hidden;
background-repeat: no-repeat !important;
background-size: cover !important;
background-position: center center !important;
text-align: center;
}
.subheader.smallsubheader{
    padding: 17px 0px 0px;
}
.subheader::before{
content: "";
height: 100%;
width: 100%;
background-color: rgba(0, 0, 0, 0.6);
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
z-index: -1;
}
.subheader h1{
color: var(--color-white);
}
.subheader .breadcrumb{
justify-content: center;
}
.breadcrumb-item.active{
color: var(--color-white) !important;
}
.breadcrumb-item + .breadcrumb-item::before{
color: var(--color-white) !important;
}
.breadcrumb-item a{
color: var(--color-white);
}
@media only screen and (max-width: 960px) {
.subheader{
padding: 50px 0px 40px;
}
}
.mlogo img{
    width: 175px;
    height: auto;
}
/*===================================================================*/
.footer{
background: var(--dark-bg-color);
width: 100%;
position: relative;
padding: 60px 0px 0px 0px;
color: var(--color-gray-300);
}
.footer-logo{
width: 80%;
}
.footer-title{
margin-bottom: 20px;
font-weight: 600;
font-size: 22px;
color: var(--color-white);
}
.socialmedia {
display: flex;
grid-gap: 20px;
list-style: none;
margin: 0;
padding: 0;
}
.socialmedia li a{
color: var(--color-gray-300);
font-size: 20px;
}
.footer-list{
margin: 0;
padding: 0;
list-style: none;
}
.footer-list li{
display: inline-block;
width: 100%;
position: relative;
}
.footer-list li a{
display: flex;
align-items: center;
height: 30px;
color: var(--color-gray-300);
}
.contactinfo{
width: 100%;
position: relative;
}
.contactinfo ul{
margin: 0;
padding: 0;
list-style: none;
}
.contactinfo ul li{
display: inline-block;
width: 100%;
position: relative;
margin-bottom: 20px;
}
.contactinfo ul li a{
display: flex;
align-items: center;
color: var(--color-gray-300);
}
.contactinfo .cicon{
font-size:18px;
}
.contactinfo .ccontent{
flex: 1;
margin-left: 15px;
}
.copyright{
padding: 40px 0px;
margin-top: 40px;
border-top: 1px solid var(--color-gray-900);
}
@media only screen and (max-width: 960px) {
.footer{
background: var(--dark-bg-color);
width: 100%;
position: relative;
padding: 30px 0px 0px 0px;
color: var(--color-gray-300);
} 
.socialmedia {
justify-content: center;
}
.footer .accordion-item{
background: var(--dark-bg-color) !important;
}
.footer .accordion-button{
background: var(--dark-bg-color);
color: var(--color-white);
font-size: 12px;
}
.footer .accordion-button:not(.collapsed){
background: var(--dark-bg-color) !important;
color: var(--color-white) !important;
}
}
/*===================================================================*/
.blog-section{
position: relative;
z-index: 1;
}
.blog-section:after{
height: 75%;
background: var(--color-primary);
content: "";
left: 0;
position: absolute;
top: 0;
width: 100%;
z-index: -1;
}
.blog-section.blog-section-second:after{
    height: 100%;
}
.blog{
position: relative;
}
.blog .thumbnail{
position: relative;
}
.blog .thumbnail a {
display: block;
position: relative;
overflow: hidden;
}
.blog .thumbnail a img {
width: 100%;
-webkit-transition: var(--transition);
transition: var(--transition);
border-radius: 10px 10px 0px 0px;
}
.blog-style-1 img{
width: 100%;
height: 200px;
object-fit: cover;
}
.blog .thumbnail a::after {
content: "";
height: 100%;
width: 100%;
background-color: rgba(0, 0, 0, 0.5);
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
visibility: hidden;
opacity: 0;
-webkit-transition: var(--transition);
transition: var(--transition);
}
.blog:hover .thumbnail a:after {
visibility: visible;
opacity: 1;
}
.blog:hover .thumbnail a img {
-webkit-transform: scale(1.1);
-ms-transform: scale(1.1);
transform: scale(1.1);
}
.blog .content .read-more-btn {
position: absolute;
top: -40px;
right: 30px;
visibility: hidden;
opacity: 0;
-webkit-transition: var(--transition);
transition: var(--transition);
}
.blog:hover .content .read-more-btn {
visibility: visible;
opacity: 1;
top: -30px;
}
.blog .content {
background-color: var(--color-white);
padding: 20px;
border-radius: 0px 0px 10px 10px;
position: relative;
}
.blog .content-seond {
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 0px 0px 10px 10px;
    position: relative;
    }
.blog .content .title {
margin-bottom: 0px;
line-height: 25px;
font-size: 15px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.blog .content-seond .title-seond {
    margin-bottom: 0px;
    line-height: 25px;
    font-size: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    }
.blog .content .title-small {
margin-bottom: 5px;
line-height: 24px;
font-size: 14px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.blog .content .title a{
color: var(--color-dark);
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.blog-style-1 .content p{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
font-size: 13px;
}
.blog-style-1 .content-seond p{
    overflow: hidden;
    font-size: 13px;
    }
.blog.blog-style-2 {
margin-bottom: 15px;
}
.blog.blog-style-2 .thumbnail {
margin-right: 20px;
width: 150px;
}
.blog.blog-style-2.first-large-blog .thumbnail {
margin-right: 0;
width: 100%;
margin-bottom: 20px;
}
.blog-style-2 .thumbnail a img{
border-radius: 10px;
}
.first-large-blog img{
height: 400px;
object-fit: cover;
}
@media only screen and (max-width: 767px) {
.first-large-blog img{
height: 250px;
object-fit: cover;
} 
}
.blog-style-2.second-large-blog img{
width: 100%;
height: 100px;
object-fit: cover;
}
.blog.blog-style-2 .content{
padding: 0;
box-shadow: none;
flex: 1;
}
.blog.blog-style-2 .content .title{
margin-bottom: 5px;
}
.blog.blog-style-2 .content p{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.blog.blog-style-2 .inner{
display: flex;
align-items: center;
}
/*===================================================================*/
.crossboundry{
position: relative;
z-index: 1;
}
.crossboundry:before{
width: 45%;
height: 100%;
background: var(--color-primary);
content: "";
left: 0;
position: absolute;
top: 0;
z-index: -1;
}
@media only screen and (max-width: 960px) {
.crossboundry:before{
width: 100%;
height: 70%;
background: var(--color-primary);
content: "";
left: 0;
position: absolute;
top: 0;
z-index: -1;
}
.swiper-buttons__box{
display: flex;
align-items: center;
justify-content: center;
margin-top: 30px;
}
}

/*===================================================================*/
.treatbox{
border-radius: 10px;
width: 100%;
position: relative;
height: 200px;
border: 1px solid var(--color-gray-200);
text-align: center;
cursor: pointer;
}
.treatbox .treatbox-img{
width: 100%;
height: 100%;
overflow: hidden;
}
.treatbox .treatbox-img img{
width: 100%;
height: 200px;
border-radius: 10px;
object-fit: cover;
transition: var(--transition);
}
.treatbox .treatbox-title{
position: absolute;
bottom: 0;
left: 0;
width: 100%;
background: rgba(255, 255, 255, 0.671);
height: 50px;
text-align: center;
padding: 0px 15px;
border-radius: 0px 0px 10px 10px;
display: flex;
align-items: center;
justify-content: center;
background-color: #b13634;
color: #fff;
}
.treatbox:hover .treatbox-img img {
-webkit-transform: scale(1.1);
-ms-transform: scale(1.1);
transform: scale(1.1);
}
/*===================================================================*/
.features_section .feature_detail {
background-color: var(--bg-white);
border-radius: 30px;
position: relative;
display: flex;
justify-content: space-between;
margin-top: 120px;
padding-top: 60px;
padding-bottom: 20px;
}
.features_section .feature_detail .feature_box {
max-width: 410px;
}
.features_section .feature_detail .left_data {
text-align: right;
padding-left: 50px;
}
.features_section .feature_detail .right_data {
padding-right: 50px;
}
.features_section .feature_detail .feature_box .data_block {
margin-bottom: 20px;
display: flex;
align-items: center;
}
.features_section .feature_detail .feature_img {
position: absolute;
left: 50%;
transform: translateX(-50%);
top: -75px;
}
.features_section .feature_detail .feature_img img {
max-width: 100%;
}
.features_section .feature_detail .left_data .data_block .ficon {
margin-right: -15px;
margin-left: 15px;
}
.features_section .feature_detail .right_data .data_block .ficon {
margin-left: -15px;
margin-right: 15px;
}
@media only screen and (max-width: 960px) {

.features_section .feature_detail {flex-direction: column-reverse; padding-top: 0px; margin-top: 0;}
.features_section .feature_detail .feature_img {position: relative; top: auto; left: auto; transform: none; width: 75%; margin: 0 auto;}
.features_section .feature_detail .feature_box {width: 100%; padding: 0 15px; text-align: center;}
.features_section .feature_detail .left_data .data_block .ficon {margin-right: 0;  margin-left: 0;}
.features_section .feature_detail .right_data .data_block .ficon {margin-left: 0;  margin-right: 0;}
.features_section .feature_detail .feature_box .data_block {margin-bottom: 0; margin-top: 10px;}
.features_section .feature_detail .feature_box { justify-content: center; margin: 0 auto; }
.feature_box.right_data .data_block {
flex-direction:column;
}
.feature_box.left_data .data_block {
flex-direction:column-reverse;
}
}
/*===================================================================*/
.cardicinv{
position: relative;
}
.cardicinv::before {
content: '';
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
background: url(../public/images/bg-image-1.svg);
background-position-x: 0%;
background-position-y: 0%;
background-size: auto;
-webkit-filter: brightness(0) saturate(100%) invert(97%) sepia(7%) saturate(503%) hue-rotate(316deg) brightness(103%) contrast(92%);
filter: brightness(0) saturate(100%) invert(97%) sepia(7%) saturate(503%) hue-rotate(316deg) brightness(103%) contrast(92%);
background-size: cover;
background-position: center;
z-index: -1;
}
@media only screen and (max-width: 960px) {
    .cardicinv::before {
    background:#f5f1ec;
    }
}
/*===================================================================*/
.crosingbox{
width: 100%;
position: relative;
border-radius: 10px;
}
.crosingbox .crosingboximg{
width: 100%;
height: 300px;
border-radius: 10px 10px 0px 0px;
overflow: hidden;
}
.crosingbox .crosingboximg img{
width: 100%;
height: 300px;
object-fit: cover;
}
.crosingbox .crosingboxcontent{
padding: 25px;
border-radius: 0px 0px 10px 10px;
background: #fff;
}
.crosingbox .crosingboxcontent h6{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
line-height: 24px;
}
.crosingboxcontent p{
margin-bottom: 0;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}
.crossboundry-about{
padding: 30px 40px 30px 50px;
}
@media only screen and (max-width: 960px) {
.crossboundry-about {
padding: 15px;
text-align: center;
}
}
/*===================================================================*/
.features-list{
margin: 0;
padding: 0;
list-style: none;
}
.features-list li {
font-weight: var(--p-medium);
font-family: var(--font-secondary);
color: var(--color-heading);
padding-left: 35px;
position: relative;
margin-bottom: 16px;
margin-top: 16px;
}
.features-list li::before {
content: "\e913";
font-family: 'icomoon';
color: var(--color-tertiary);
font-size: 19px;
position: absolute;
left: 0;
top: -3px;
}
.features-list li a{
color: var(--color-dark);
}
/*===================================================================*/
.video-area-1 {
position: relative;
}
.video-area-1::before {
content: '';
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-image: url(../public/images/bg-image-13.png);
background-repeat: no-repeat;
background-position: center top;
background-size: contain;
}
.video-gallery {
position: relative;
z-index: 1;
}
.video-gallery .thumbnail {
position: relative;
}
.video-gallery .thumbnail img {
border-radius: 0 80px 0 120px;
width: 100%;
}
.video-gallery .thumbnail .video-play-btn {
position: absolute;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%, -50%);
-ms-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
border: 0;
width: 80px;
height: 80px;
line-height: 82px;
-webkit-transition: all 1s cubic-bezier(0, 0, 0.2, 1);
transition: all 1s cubic-bezier(0, 0, 0.2, 1);
border-radius: 50%;
background: var(--color-secondary);
margin: 0 auto;
display: inline-block;
font-size: 24px;
text-align: center;
color: var(--color-white);
}
@media only screen and (max-width: 575px) {
.video-gallery .thumbnail .video-play-btn {
width: 60px;
height: 60px;
line-height: 62px;
}
}
.video-gallery .thumbnail .video-play-btn i {
margin-left: 5px;
}
.video-gallery .thumbnail .video-play-btn:before {
content: '';
display: block;
position: absolute;
top: 0;
right: 0;
z-index: -1;
bottom: 0;
left: 0;
-webkit-transition: var(--transition);
transition: var(--transition);
border-radius: 50%;
border: 1px solid rgba(255, 255, 255, 0.3);
-webkit-animation: ripple 2s linear infinite;
animation: ripple 2s linear infinite;
}
.video-gallery .thumbnail .video-play-btn:after {
content: '';
display: block;
position: absolute;
top: 0;
right: 0;
z-index: -1;
bottom: 0;
left: 0;
-webkit-transition: var(--transition);
transition: var(--transition);
border-radius: 50%;
border: 1px solid rgba(255, 255, 255, 0.3);
-webkit-animation: ripple 2s linear 1s infinite;
animation: ripple 2s linear 1s infinite;
}
.video-gallery .thumbnail:before {
content: "";
height: 100%;
width: 100%;
background-color: rgba(0, 0, 0, 0.3);
border-radius: 0 80px 0 120px;
position: absolute;
top: 0;
bottom: 0;
left: 0;
right: 0;
}
.video-gallery .shape-group{
list-style: none;
}
.video-gallery .shape-group li {
margin: 0;
position: absolute;
z-index: -1;
}
.video-gallery .shape-group li.shape-1 {
top: -70px;
right: -70px;
}
.video-gallery .shape-group li.shape-2 {
top: -100px;
right: 15px;
z-index: 1;
}
@media only screen and (max-width: 767px) {
.video-gallery .shape-group li.shape-1 {
top: -70px;
right: 0;
}
.video-gallery .shape-group li.shape-2 {
display: none;
}
}
.video-gallery .shape-group li.shape-3 {
bottom: -40px;
left: -130px;
}

/*===================================================================*/
.edu-counterup {
border-radius: 10px;
text-align: center;
padding: 38px 30px;
}

@media only screen and (max-width: 479px) {
.edu-counterup {
padding: 25px;
}
}
.edu-counterup .title {
margin-bottom: 0;
font-size: 13px;
text-transform: uppercase;
font-weight: var(--p-medium);
}
.edu-counterup.counterup-style-5.primary-color {
background-color: rgba(26, 182, 157, 0.1);
}
.edu-counterup.counterup-style-5.primary-color .count-number {
color: var(--color-primary);
}
.edu-counterup.counterup-style-5.secondary-color {
background-color: rgba(255, 91, 92, 0.1);
}
.edu-counterup.counterup-style-5.secondary-color .count-number {
color: var(--color-secondary);
}
.edu-counterup.counterup-style-5.extra02-color {
background-color: rgba(142, 86, 255, 0.1);
}
.edu-counterup.counterup-style-5.extra02-color .count-number {
color: var(--color-extra02);
}
.edu-counterup.counterup-style-5.extra05-color {
background-color: rgba(248, 150, 36, 0.1);
}
.edu-counterup.counterup-style-5.extra05-color .count-number {
color: var(--color-extra05);
}
/*===================================================================*/
.apponimentf{
box-shadow: 0px 5px 120px 0px rgba(39, 71, 125, 0.15);
background: var(--color-white);
border-radius: 10px;
}
.apponimentf .apponimentfHeader{
background: var(--dark-bg-color);
padding: 35px;
border-radius: 10px 10px 0px 0px;
color: var(--color-white);
position: relative;
padding-right: 100px;
}
.apponimentfBody{
padding: 5px;
}
.ripple-icon {
display: inline-block;
position: absolute;
top:25px;
right: 25px;
z-index: 1;
}
.ripple-icon::after, .ripple-icon::before {
content: "";
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
background-color: var(--color-primary);
animation: ripple-sm 4s infinite ease-in-out;
z-index: -1;
border-radius: 50%;
transition: all ease 0.4s;
}
.ripple-icon::after {
animation-delay: 1.2s;
}
.ripple-icon i {
background-color: var(--theme-color);
color: var(--white-color);
font-size: 22px;
}
.ripple-icon i {
width: 70px;
height: 70px;
background-color: var(--color-primary);
color: var(--color-white);
border: 4px solid rgba(64, 144, 77, 0.3);
display: inline-block;
text-align: center;
line-height: 60px;
font-size: 2em;
border-radius: 50%;
}
.ripple-animation, .number-meta .icon:after, .number-meta .icon:before, .play-btn:after, .play-btn:before {
animation-duration: var(--ripple-ani-duration);
animation-timing-function: ease-in-out;
animation-iteration-count: infinite;
animation-name: ripple;
}

@keyframes ripple {
0% {
transform: scale(1);
opacity: 0;
}
30% {
opacity: 0.4;
}
100% {
transform: scale(1.5);
opacity: 0;
}
}
@keyframes ripple-md {
0% {
transform: scale(1);
opacity: 0;
}
30% {
opacity: 0.6;
}
100% {
transform: scale(1.9);
opacity: 0;
}
}
@keyframes ripple-sm {
0% {
transform: scale(1);
opacity: 0;
}
30% {
opacity: 0.2;
}
100% {
transform: scale(1.9);
opacity: 0;
}
}
@keyframes ripple-xs {
0% {
transform: scale(1);
opacity: 0;
}
30% {
opacity: 0.1;
}
100% {
transform: scale(1.7);
opacity: 0;
}
}
/*===================================================================*/
.homeSlider{
width: 100%;
position: relative;
}
.homeSlider img{
width: 100%;
}
.homeSlider .homeSlider-content{
position: absolute;
top:0;
left: 8%;
max-width: 625px;
height: 100%;
display: flex;
align-items: center;
}
.homeSlider-content h2{
font-size: 36px;
line-height: 52px;
}
.homeSlider-content .pre-title{
font-family: var(--font-third);
display: inline-block;
width: 100%;
font-size: 24px;
font-weight: 700;
margin-bottom: 20px;
color: var(--color-primary);
}
@media only screen and (max-width: 960px) {
.homeSlider {
width: 100%;
position: relative;
height: 450px;
}
.homeSlider img {
width: 100%;
height: 450px;
object-fit: cover;
}
.homeSlider .homeSlider-content {
position: absolute;
top: 0;
left: 0;
max-width: 100%;
width: 100%;
height: 100%;
display: flex;
align-items: center;
text-align: center;
padding: 15px;
}
.homeSlider-content h2 {
font-size: 20px;
line-height: 32px;
}
}
/*===================================================================*/
.homeswiper + .home-features, .home-features {
-webkit-transform: translateY(-6rem);
-ms-transform: translateY(-6rem);
transform: translateY(-6rem);
margin-bottom: -4.2rem;
position: relative;
z-index: 500;
}
.home-features-item{
box-shadow: 0 4px 12px rgba(33, 44, 55, 0.06);
width: 100%;
position: relative;
padding: 25px;
min-height: 300px;
background: var(--color-white);
}
/*===================================================================*/
.testiomnial .swiper-wrapper .swiper-slide {
padding: 0 10px;
}
.testimonial-grid {
border: 1px solid #eee;
background-color: var(--color-white);
border-radius: 10px;
padding: 30px 20px;
position: relative;
z-index: 1;
overflow: hidden;
margin: 30px 0px 50px 0px;
}
.testimonial-grid:after {
content: url(../public/images/shape-1.png);
position: absolute;
top: -5px;
right: -5px;
z-index: -1;
}
.testimonial-grid .thumbnail {
display: inline-block;
position: relative;
margin-bottom: 35px;
}
.testimonial-grid .thumbnail img {
border-radius: 50%;
width: 75px;
height: 75px;
object-fit: cover;
}
.testimonial-grid .thumbnail .qoute-icon {
height: 34px;
width: 34px;
line-height: 30px;
font-size: 13px;
color: var(--color-white);
background-color: var(--color-primary);
border-radius: 50%;
border: 4px solid var(--color-white);
display: block;
text-align: center;
position: absolute;
bottom: -8px;
right: -12px;
}
.testimonial-grid .content p {
margin-bottom: 20px;
font-size: 13px;
line-height: 24px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 6;
-webkit-box-orient: vertical;
}
.testimonial-grid .content .rating-icon {
margin-bottom: 6px;
}
.testimonial-grid .content .rating-icon i {
color: #f8b81f;
}
.testimonial-grid .content .title {
margin-bottom: 2px;
font-size: 14px;
}
.testimonial-grid.testimonial-style-3 {
text-align: center;
margin-bottom: 40px;
margin-top: 30px;
}
.testimonial-grid.testimonial-style-3:after {
display: none;
}
.home-one-testimonial .swiper-wrapper .swiper-slide {
padding: 0 10px;
}
.home-one-testimonial .testimonial-grid {
margin: 30px 0px 50px 0px;
}
.videotestibox{
width: 100%;
position: relative;
border: 1px solid #eee;
background: var(--color-white);
border-radius: 10px;
}
.videotestibox .videotestibox-content{
padding: 20px;
height: 100px;
}
.videotestibox-content h6{
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
}
/*===================================================================*/
.form-group{
width: 100%;
position: relative;
}
.form-group label{
width: 100%;
margin-bottom: 5px;
font-size: 14px;
font-weight: 500;
}
.form-group input{
width: 100%;
height: 50px;
border: 1px solid #ddd;
border-radius: 5px;
padding: 15px;
}
.form-group textarea{
width: 100%;
height: 150px;
border: 1px solid #ddd;
border-radius: 5px;
padding: 15px;
}
/*===================================================================*/
.cta-banner-area{
padding: 102px 0;
background-color: #f0f4f5;
position: relative;
z-index: 1;
}
.cta-banner-area::before{
content: '';
position: absolute;
left: 0;
bottom: 0;
width: 100%;
height: 100%;
background-image: url(/public/images/cta.svg);
-webkit-filter: brightness(0) saturate(100%) invert(52%) sepia(75%) saturate(451%) hue-rotate(121deg) brightness(96%) contrast(90%);
filter: brightness(0) saturate(100%) invert(52%) sepia(75%) saturate(451%) hue-rotate(121deg) brightness(96%) contrast(90%);
background-position: center bottom;
background-repeat: no-repeat;
background-size: cover;
}
.edu-cta-banner{
position: relative;
z-index: 1;
}
.cta-banner-area .shape-group{
margin: 0;
padding: 0;
list-style: none;
}
.edu-cta-banner .shape-group li{
position: absolute;
z-index: -1;
}
.edu-cta-banner .shape-group li.shape-01{
top: 109px;
left: -68px;
}
/*===================================================================*/
.crossbslider{
position: relative;
width: 100%;
}
.crossbslider .swiper-buttons__box{
position: absolute;
width: 100%;
display: flex;
left: 0;
top: 40%;
z-index: 9999999;
align-items: center;
justify-content: space-between;
}
.crossbslider .swiper-button-prev span svg{
transform: rotate(-180deg);
}
.crossbslider .swiper-buttons__box svg{
color: var(--color-white) !important;
}
.crdicslider{
position: relative;
width: 100%;
}
.crdicslider .swiper-buttons__box{
position: absolute;
width: 100%;
display: flex;
left: 0;
top: 40%;
z-index: 9999999;
align-items: center;
justify-content: space-between;
}
.crdicslider .swiper-button-prev span svg{
transform: rotate(-180deg);
}
/*===================================================================*/
.videotestslide{
width: 100%;
position: relative;
}
.videotestslide .swiper-buttons__box{
position: absolute;
width: 100%;
display: flex;
left: 0;
top: 40%;
z-index: 9999999;
align-items: center;
justify-content: space-between;
}
.videotestslide .swiper-button{
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--color-primary);
    color: #fff;
    justify-content: center;
}
.videotestslide .swiper-button-prev span svg{
transform: rotate(-180deg);
}
.tetestslide{
    width: 100%;
    position: relative;
}
.tetestslide .swiper-buttons__box{
    position: absolute;
    width: 100%;
    display: flex;
    left: 0;
    top: 40%;
    z-index: 9999999;
    align-items: center;
    justify-content: space-between;
    }
    .tetestslide .swiper-button-prev span svg{
        transform: rotate(-180deg);
        }
        .tetestslide .swiper-button{
            display: flex;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: var(--color-primary);
            color: #fff;
            justify-content: center;
        }
/*===================================================================*/
.galarybox .lg-react-element {
display: flex;
flex-wrap: wrap;
gap: 10px;
}
/*===================================================================*/
.innertestimonialbox{
width: 100%;
position: relative;
border: 1px solid #ddd;
margin-bottom: 15px;
padding: 20px;
border-radius: 10px;
}
.innertestimonialbox .innertestimonialboximg{
width: 100%;
position: relative;
}
.innertestimonialboximg img{
width: 100%;
height: 120px;
object-fit: cover;
border-radius: 10px;
}
.innertestimonialboxtext{
width: 100%;
height: 100px;
overflow-y: auto;
}
@media only screen and (max-width: 960px) {
.innertestimonialboximg img{
width: 100%;
height: 200px;
object-fit: cover;
border-radius: 10px;
margin-bottom: 20px;
} 
.innertestimonialboxtext{
width: 100%;
height: auto;
overflow-y: auto;
}
}
/*===================================================================*/
.bloglistbox{
width: 100%;
position: relative;
margin-bottom: 30px;
border-bottom: 1px solid var(--color-gray-200);
padding-bottom: 20px;
}
.bloglistbox.bloglistbox-details{
    border-bottom: 0px;
}
.bloglistbox .bloglistbox-thumb{
width: 100%;
position: relative;
overflow: hidden;
margin-bottom: 20px;
}
.bloglistbox .bloglistbox-thumb img{
width: 100%;
height: 350px;
object-fit: cover;
border-radius: 10px;
}
.bloglistbox-details .bloglistbox-thumb img{
    height: auto !important;
}
.bloglistbox .title{
-webkit-box-orient: horizontal;
display: -webkit-box;
font-size: 28px;
overflow: hidden;
text-overflow: ellipsis;
}
.bloglistbox .title a{
    color: var(--color-dark);
}
.sidebarbox{
width: 100%;
position: relative;
padding: 25px;
border-radius: 10px;
margin-bottom: 20px;
background: #efece7;
}
.sidebarbox .title{
font-size: 18px;
margin-bottom: 20px;
}
.sidelist{
width: 100%;
position: relative;
}
.sidelist ul{
margin: 0;
padding: 0;
list-style: none;
}
.sidelist ul li{
display: inline-block;
width: 100%;
position: relative;
}
.sidelist ul li a{
display: flex;
align-items: center;
justify-content: space-between;
height: 30px;
color: var(--color-dark);
}
/*===================================================================*/
.ltblog{
width: 100%;
display: flex;
position: relative;
align-items: center;
margin-bottom: 20px;
}
.ltblog:last-child{
margin-bottom: 0px;
}
.ltblog .ltblog-img{
width: 100px;
overflow: hidden;
}
.ltblog .ltblog-img img{
width: 100px;
height: 80px;
border-radius: 10px;
object-fit: cover;
}
.ltblog .ltblog-content{
flex: 1;
margin-left: 10px;
}
.ltblog .title{
font-size: 16px;
margin-bottom: 0px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
line-height: 24px;
}
.ltblog .title a{
color: var(--color-dark);
}
.listmeta{
width: 100%;
position: relative;
}
.listmeta ul{
margin: 0;
padding: 0;
list-style: none;
}
.listmeta ul li{
display: inline-block;
font-size: 12px;
margin-right: 15px;
}
.listmeta ul li.large{
font-size: 14px;
}
.listmeta ul li i{
margin-right: 5px;
}
/*===================================================================*/
.form-group{
width: 100%;
position: relative;
}
.form-group label{
width: 100%;
display: flex;
align-items: center;
margin-bottom: 5px;
}
.form-group input{
width: 100%;
height: 50px;
border: 1px solid var(--color-gray-200);
}
.gmap iframe {
border-radius: 20px;
height: 617.98px !important;
}
.contacts-item{
width: 100%;
position: relative;
display: flex;
align-items: center;
color: var(--color-gray-100);
}
.contacts-item .contacts-item-icon{
width: 70px;
height: 70px;
margin-right: 20px;
}
.contacts-item .contacts-content{
flex: 1;
}
.contacts-content .title{
color: var(--color-white) !important;
font-size: 20px;
font-weight:600;
}
.contactsect{
background: #040f1f;
}
@media only screen and (max-width: 960px) {
.contacts-item {
width: 100%;
position: relative;
display: flex;
align-items: center;
color: var(--color-gray-100);
flex-direction: column;
justify-content: center;
text-align: center;
margin: 10px 0px;
}
}
/*==================================================*/
/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
position: fixed;
margin: auto;
width: 350px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.menu-modal.left .modal-dialog {
position: fixed;
margin: auto;
width: 270px;
height: 100%;
-webkit-transform: translate3d(0%, 0, 0);
-ms-transform: translate3d(0%, 0, 0);
-o-transform: translate3d(0%, 0, 0);
transform: translate3d(0%, 0, 0);
}
.modal.left .modal-content,
.modal.right .modal-content {
height: 100%;
overflow-y: auto;
}
.modal.left .modal-body,
.modal.right .modal-body {
padding: 15px 15px 80px;
}
.menu-modal.modal.fade .modal-dialog {
transition: transform .3s ease-out;
transform: translate(0, 0px) !important;
}
/*Left*/
.modal.left.fade .modal-dialog {
left: -320px;
-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
-moz-transition: opacity 0.3s linear, left 0.3s ease-out;
-o-transition: opacity 0.3s linear, left 0.3s ease-out;
transition: opacity 0.3s linear, left 0.3s ease-out;
}
.modal.left.fade.show .modal-dialog {
left: 0;
}
/*Right*/
.modal.right.fade .modal-dialog {
right: -350px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}
.modal.right.fade.show .modal-dialog {
right: 0;
}
.menu-modal .modal-content {
background: var(--color-white);
border-radius: 0px;
}
/*======================*/
/*===================================================================*/
.sidemenu {
width: 100%;
position: relative;
/* padding-top: 15px; */
}
.sidemenu ul {
margin: 0;
padding: 0;
list-style: none;
}
.sidemenu ul li {
display: inline-block;
width: 100%;
position: relative;
font-size: 14px;
}
.sidemenu ul li a{
    color: var(--color-dark);
}
.sidemenu ul li .sub-menu-inner {
display: flex;
align-items: center;
justify-content: space-between;
padding: 0px 15px;
height: 45px;
border-bottom: 1px solid var(--color-gray-200);
}
.sub-menu-inner .right {
width: 50px;
text-align: right;
}
.sidemenu ul li a {
-webkit-transition: 0.2s linear;
-moz-transition: 0.2s linear;
-ms-transition: 0.2s linear;
-o-transition: 0.2s linear;
transition: 0.2s linear;
}
.sidemenu ul li ul {
background: var(--color-lighten02);
padding: 10px 0px;
}
.sidemenu ul li ul li a {
display: flex;
height: 30px;
align-items: center;
padding: 0px;
font-size: 12px;
}
/*===================================================================*/
.treatment .swiper{
padding-bottom: 50px;
}
/*===================================================================*/
/*===================================================================*/
/*===================================================================*/
@font-face {
font-family: 'icomoon';
src:  url('../public/fonts/icomoon.eot');
src:  url('../public/fonts/icomoon.eot') format('embedded-opentype'),
url('../public/fonts/icomoon.ttf') format('truetype'),
url('../public/fonts/icomoon.woff') format('woff'),
url('../public/fonts/icomoon.svg') format('svg');
font-weight: normal;
font-style: normal;
font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
/* use !important to prevent issues with browser extensions that change fonts */
font-family: 'icomoon' !important;
speak: never;
font-style: normal;
font-weight: normal;
font-variant: normal;
text-transform: none;
line-height: 1;

/* Better Font Rendering =========== */
-webkit-font-smoothing: antialiased;

-moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
content: "\e95c";
}
.icon-remove:before {
content: "\e95d";
}

.icon-east:before {
content: "\e95a";
}
.icon-west:before {
content: "\e95b";
}
.icon-envelope:before {
content: "\f0e0";
}
.icon-angle-left:before {
content: "\f104";
}
.icon-angle-right:before {
content: "\f105";
}
.icon-share-alt:before {
content: "\f1e0";
}
.icon-1:before {
content: "\e900";
}
.icon-2:before {
content: "\e901";
}
.icon-3:before {
content: "\e902";
}
.icon-4:before {
content: "\e903";
}
.icon-5:before {
content: "\e904";
}
.icon-6:before {
content: "\e905";
}
.icon-7:before {
content: "\e906";
}
.icon-8:before {
content: "\e907";
}
.icon-9:before {
content: "\e908";
}
.icon-10:before {
content: "\e909";
}
.icon-11:before {
content: "\e90a";
}
.icon-12:before {
content: "\e90b";
}
.icon-13:before {
content: "\e90c";
}
.icon-14:before {
content: "\e90d";
}
.icon-15:before {
content: "\e90e";
}
.icon-16:before {
content: "\e90f";
}
.icon-17:before {
content: "\e910";
}
.icon-18:before {
content: "\e911";
}
.icon-19:before {
content: "\e912";
}
.icon-20:before {
content: "\e913";
}
.icon-21:before {
content: "\e914";
}
.icon-22:before {
content: "\e915";
}
.icon-23:before {
content: "\e916";
}
.icon-24:before {
content: "\e917";
}
.icon-25:before {
content: "\e918";
}
.icon-26:before {
content: "\e919";
}
.icon-27:before {
content: "\e91a";
}
.icon-28:before {
content: "\e91b";
}
.icon-29:before {
content: "\e91c";
}
.icon-30:before {
content: "\e91d";
}
.icon-31:before {
content: "\e91e";
}
.icon-32:before {
content: "\e91f";
}
.icon-33:before {
content: "\e920";
}
.icon-34:before {
content: "\e921";
}
.icon-35:before {
content: "\e922";
}
.icon-36:before {
content: "\e923";
}
.icon-37:before {
content: "\e924";
}
.icon-38:before {
content: "\e925";
}
.icon-39:before {
content: "\e926";
}
.icon-40:before {
content: "\e927";
}
.icon-41:before {
content: "\e928";
}
.icon-42:before {
content: "\e929";
}
.icon-43:before {
content: "\e92a";
}
.icon-44:before {
content: "\e92b";
}
.icon-45:before {
content: "\e92c";
}
.icon-46:before {
content: "\e92d";
}
.icon-47:before {
content: "\e92e";
}
.icon-48:before {
content: "\e92f";
}
.icon-49:before {
content: "\e930";
}
.icon-50:before {
content: "\e931";
}
.icon-51:before {
content: "\e932";
}
.icon-52:before {
content: "\e933";
}
.icon-53:before {
content: "\e934";
}
.icon-54:before {
content: "\e935";
}
.icon-55:before {
content: "\e936";
}
.icon-56:before {
content: "\e937";
}
.icon-57:before {
content: "\e938";
}
.icon-58:before {
content: "\e939";
}
.icon-59:before {
content: "\e93a";
}
.icon-60:before {
content: "\e93b";
}
.icon-61:before {
content: "\e93c";
}
.icon-62:before {
content: "\e93d";
}
.icon-63:before {
content: "\e93e";
}
.icon-64:before {
content: "\e93f";
}
.icon-65:before {
content: "\e940";
}
.icon-66:before {
content: "\e941";
}
.icon-67:before {
content: "\e942";
}
.icon-68:before {
content: "\e943";
}
.icon-69:before {
content: "\e944";
}
.icon-70:before {
content: "\e945";
}
.icon-71:before {
content: "\e946";
}
.icon-72:before {
content: "\e947";
}
.icon-73:before {
content: "\e948";
}
.icon-74:before {
content: "\e949";
}
.icon-75:before {
content: "\e94a";
}
.icon-76:before {
content: "\e94b";
}
.icon-77:before {
content: "\e94c";
}
.icon-78:before {
content: "\e94d";
}
.icon-79:before {
content: "\e94e";
}
.icon-80:before {
content: "\e94f";
}
.icon-81:before {
content: "\e950";
}
.icon-82:before {
content: "\e951";
}
.icon-83:before {
content: "\e952";
}
.icon-84:before {
content: "\e953";
}
.icon-85:before {
content: "\e954";
}
.icon-86:before {
content: "\e955";
}
.icon-87:before {
content: "\e956";
}
.icon-88:before {
content: "\e957";
}
.icon-89:before {
content: "\e958";
}
.icon-phone:before {
content: "\e959";
}
.icon-star-empty:before {
content: "\e9d7";
}
.icon-star-half:before {
content: "\e9d8";
}
.icon-star-full:before {
content: "\e9d9";
}
.icon-minus:before {
content: "\ea0b";
}
.icon-share2:before {
content: "\ea82";
}
.icon-facebook:before {
content: "\ea90";
}
.icon-instagram:before {
content: "\ea92";
}
.icon-twitter:before {
content: "\ea96";
}
.icon-youtube:before {
content: "\ea9d";
}
.icon-linkedin2:before {
content: "\eaca";
}
.icon-pinterest:before {
content: "\ead1";
}
/*=====================ANAND==============================================*/
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show{
color: #40904D !important;
}
/*===================================================================*/
.dropdown-item.active, .dropdown-item:active{
    background-color: #40904D !important;
}
/*===================================================================*/
.accordbox{
width: 100%;
position: relative;
}
.accordbox .accordion-item{
    margin-bottom: 10px;
    border: 1px solid var(--color-gray-200) !important;
    border-radius: 5px !important;
}
.accordbox .accordion-button{
    border-radius: 5px !important;
    font-weight: 600;
  font-size: 15px;
  padding: 19px 15px 15px;
}
.accordbox .accordion-button:not(.collapsed){
background: var(--color-primary) !important;
color: var(--color-white) !important;
}
/*===================================================================*/
.cardio-heading{
    background-color: var(--color-primary);
    color:#fff ;
    border-radius: 10px;
    padding: 5px;
}
/*===================================================================*/
@media only screen and (max-width: 960px) {
.treatbox{
border-radius: 10px;
width: 100%;
position: relative;
height: 350px;
border: 1px solid var(--color-gray-200);
text-align: center;
cursor: pointer;
}
.treatbox .treatbox-img img{
width: 100%;
height: 300px;
border-radius: 10px;
object-fit: cover;
transition: var(--transition);
}
.bloglistbox .title {
-webkit-box-orient: horizontal;
display: -webkit-box;
font-size: 20px;
overflow: hidden;
text-overflow: ellipsis;
}
}
/*===================================================================*/
/*===================================================================*/
/*===================================================================*/